import { Component, OnInit, inject, Renderer2 } from '@angular/core';
import { RouterLink, RouterOutlet, RouterLinkActive, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ShortenPipe } from '../../pipes/shorten.pipe';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '../../services/api.service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { create } from 'lodash';


@Component({
  selector: 'app-account',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterLinkActive,
    ShortenPipe,
    NgbTooltip
  ],
  templateUrl: './account.component.html',
  styleUrl: './account.component.css'
})
export class AccountComponent implements OnInit {
  // Dependency Injection  
  router: Router = inject(Router);
  activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  apiService = inject(ApiService);
  toastrService: ToastrService = inject(ToastrService);
  authService = inject(AuthService);
  renderer: Renderer2 = inject(Renderer2);

  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;
  authToken?: string;
  
  signingOut = false;

  // Facebook Login
  fbAppId: string = '986388786047772';
  fbConfigId: string = '884446506535600';
  fbRedirectUrl: string = `https://genesis${environment.production ? '' : '-dev'}.hubbleapp.ai/account`;
  fbState: string = '';
  fbLoginUrl: string = '';
  fbConnectionStatus: boolean = false;
  fbConnectionStatusLoading: boolean = false;
  fbConnectionData = {
    created: null,
    name: null,
  };

  ngOnInit(): void {
    this.authService.checkUserSignedIn().then(checkSign => {
      if (!checkSign) {
        this.authService.signOut();
      } else {
        this.authService.getIdToken().then(token => {
          console.log("authService getIdToken()", token);
          this.authToken = token;

          // Build Facebook Login URL
          this.fbState = this.authenticatedUser?.uid || '';
          this.fbLoginUrl = `https://www.facebook.com/v20.0/dialog/oauth?client_id=${this.fbAppId}&redirect_uri=${encodeURI(this.fbRedirectUrl)}&state=${encodeURI(this.fbState)}&config_id=${this.fbConfigId}&response_type=code`;

          // Subscribe to queryParams to get Facebook Authorization Code on redirect
          this.activatedRoute.queryParams.subscribe(params => {
            console.log("All Query Params:", params);
            if(params['code'] && params['state']) {
              // Redirected from Facebook Login
              console.log("FB Authorization Code:", params['code']);
              console.log("FB State:", params['state']);

              this.fbConnectionStatusLoading = true; // Display loading indicator while exchanging code for access token

              // Send code & state to backend to get Facebook Access Token using apiService call to users?get=meta-token-code endpoint
              let request = '/users?get=meta-token-code&code='+encodeURI(params['code'])+'&state='+encodeURI(params['state']);
              this.apiService.get(request)
              .then(response => {
                console.log("apiService Get response", response);          
                this.toastrService.success('Facebook connection successful', 'Success');
                this.checkFacebookConnection();
              })
              .catch(error => {
                console.log("apiService Get error", error.message);
                this.toastrService.error(error.message, 'Meta error');
              });

            } else {
              // Not redirected from Facebook Login
              this.checkFacebookConnection();

            }
          });        

        }).catch(error => {
          console.log("getIdToken error", error);
        });           
      }
    });
  }

  doSignOut() {
    this.signingOut = true;
    this.authService.signOut()
    .then(() => {
      console.log("Sign out succeeded");
      this.signingOut = false;
      this.router.navigate(['/', '/']);
    })
    .catch(error => {
      console.error("Sign out failed", error);
      this.signingOut = false;
    });
  }

  async copyToClipboard(str: string) {
    await navigator.clipboard.writeText(str);
    this.toastrService.success(str.substring(0, 100) + '...', 'Copied to clipboard');
  }

  async checkFacebookConnection() {
    this.fbConnectionStatusLoading = true;
    this.fbConnectionStatus = false;
    let request = '/users?get=meta-token-check';
    this.apiService.get(request)
    .then(response => {
      console.log("apiService Get response", response);
      this.fbConnectionStatusLoading = false;
      this.fbConnectionStatus = (response.data && response.data.access_token) ? true : false;
      this.fbConnectionData.created = (response.data && response.data.created) ? response.data.created : '';
      this.fbConnectionData.name = (response.data && response.data.name) ? response.data.name : '';
    })
    .catch(error => {
      console.log("apiService Get error", error.message);
      this.fbConnectionStatusLoading = false;
      this.fbConnectionStatus = false;
      this.toastrService.error(error.message, 'Facebook Connection Error');
    })
  }

}