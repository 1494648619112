import { Component, OnInit, inject } from '@angular/core';
import { RouterLink, RouterOutlet, RouterLinkActive, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../services/api.service';
import { User } from '../../interfaces/user';
import * as _ from 'lodash';


@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterLinkActive
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css'
})
export class AdminComponent implements OnInit {
  // Dependency Injection  
  router: Router = inject(Router);
  authService = inject(AuthService);
  apiService = inject(ApiService);

  // User Authentication
  authenticatedUser = this.authService.authenticatedUser;

  authToken?: string;

  loadingUsers = false;
  users: User[] = [];


  ngOnInit(): void {
    // Check that user is authenticated & ADMIN (should be the case if this path is protected with AuthGuardService in app.routes.ts)
    this.authService.checkUserSignedIn().then(checkSign => {
      if (!checkSign) {
        // User is NOT sign in => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else if(!this.authService.isAdmin) {
        // User is NOT Admin => Call AuthService.signOut() to redirect to Sign in page
        this.authService.signOut();
      } else {
        // ADMIN is signed in
        this.authService.getIdToken()
        .then(token => {
              console.log("authService getIdToken()", token);
              this.authToken = token;
              // Implement page logic
              this.getUsers();


        }).catch(error => {
          console.log("getIdToken error", error);
        });
      }
    });
  }

  getUsers() {
    return new Promise<User[]>((resolve, reject) => {
      this.loadingUsers = true;
      let request = '/admin?get=users';
      this.apiService.get(request).then((data) => {
        console.log('getUsers Data', data);
        this.loadingUsers = false;
        _.forEach(data.data, (user) => {
          this.users.push({
            id: user.id,
            email: user.email,
            name: user.name,
            photo: user.photo,
            isActive: user.isActive,
            signedIn: user.signedIn,
            updated: user.updated,
            created: user.created,
            dashboards: user.dashboards
          });
        });
        resolve(this.users);
      })
      .catch((error) => {
        console.log('Error', error);
        this.loadingUsers = false;
        reject(error.message);
      })   
    });
  }

}
